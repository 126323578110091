import { ForwardToInbox } from '@mui/icons-material'
import CheckIcon from '@mui/icons-material/Check'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { TreeView } from '@mui/x-tree-view/TreeView'
import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import ConfirmationDialog from 'components/atoms/ConfirmationDialog'
import Loading from 'components/molecules/Loading'
import { format, toDate } from 'date-fns-tz'
import { useAtom } from 'jotai'
import { isEmpty } from 'lodash'
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { alertAtom } from 'stores'
import { deleteUser, disableEnableUser } from 'stores/user'
import { User, UsersType } from 'types/user'
import { dateFilterParams } from 'utils/ag-grid'

const UserList = ({
  loading,
  userList,
  onViewOrEditUser,
  onReInviteUser,
  updateUsers,
}: {
  loading: boolean
  userList: UsersType[]
  onViewOrEditUser: (rowToEdit: UsersType | null) => void
  onReInviteUser: (user: UsersType) => void
  updateUsers: () => void
}): JSX.Element => {
  const [, setAlert] = useAtom(alertAtom)
  const [rowsData, setRowsData] = useState<UsersType[]>([])
  const [rowData, setRowData] = useState<UsersType | null>(null)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 20, 50, 100]
  }, [])
  const [selectedUser, setUser] = useState<User | null>(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [, setUserLoading] = useState<boolean>(false)
  const gridRef = useRef<AgGridReact<UsersType>>(null)
  const [hasGridFilter, setHasGridFilter] = useState(false)

  const dateFormatter = (params: ValueFormatterParams): string => {
    return params.value
      ? format(toDate(params.value, { timeZone: 'Etc/UTC' }), 'Pp')
      : ''
  }

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      suppressMovable: true,
    }
  }, [])

  const handleUserDelete = (user: User): void => {
    setConfirmDialogOpen(true)
    setUser(user)
  }

  const handleUserReInvite = (user: UsersType): void => {
    onReInviteUser(user)
  }

  const handleDeleteClose = (): void => {
    setConfirmDialogOpen(false)
    setUser(null)
  }

  const handleDeleteConfirm = (): void => {
    if (selectedUser && selectedUser.adObjectId) {
      deleteUser(selectedUser.adObjectId)
        .then(() => {
          updateUsers()
          setAlert({
            show: true,
            type: 'success',
            message: 'User deleted',
            autoHideDuration: 2000,
          })
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to delete user',
            autoHideDuration: 2000,
          })
        })
    } else {
      throw new Error('Invalid User: adObjectId is missing')
    }
    setConfirmDialogOpen(false)
  }

  const handleCellClick = (params: CellClickedEvent): void => {
    if (
      params.data.adObjectId &&
      params.colDef.field !== '' &&
      params.colDef.field !== 'billingAccountNames' &&
      params.colDef.field !== 'isActive'
    ) {
      onViewOrEditUser(params.data)
    }
  }

  const filterChanged = (): void => {
    const filterModel = gridRef.current?.api.getFilterModel()
    if (!isEmpty(filterModel)) {
      setHasGridFilter(true)
    }

    // console.log('### api.forEachNodeAfterFilterAndSort() ###')
    // gridRef.current?.api.forEachNodeAfterFilterAndSort(updateRow)
  }

  const clearFilters = useCallback(() => {
    gridRef?.current?.api.resetColumnState()
    gridRef?.current?.api.setFilterModel(null)
    setHasGridFilter(false)
  }, [])

  const handleUserActive = (
    event: ChangeEvent<HTMLInputElement>,
    user: User
  ): void => {
    setUserLoading(true)
    disableEnableUser(user.adObjectId, !user.isActive)
      .then(() => {
        updateUsers()
        setAlert({
          show: true,
          type: 'success',
          message: `Updated ${user.displayName} status.`,
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: `Failed to updated user ${user.fullName} status.`,
          autoHideDuration: 2000,
        })
        setUserLoading(false)
      })
  }

  const getRowStyle = (params: any): any => {
    if (params.data.adObjectId === null) {
      return { background: '#ff586e1c', cursor: 'not-allowed' }
    }
  }

  const columnDefs: ColDef[] = [
    { headerName: 'Name', field: 'fullName' },
    { headerName: 'Email', field: 'emailAddress', width: 300 },
    {
      headerName: 'Billing Accounts',
      field: 'billingAccountNames',
      width: 250,
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return params.value && params.value.length > 1 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box component="span">
              {params.value[0]} <b>+{params.value.length - 1}</b>
            </Box>
            <IconButton
              aria-label="more"
              size="small"
              sx={{ ml: 1, height: '30px', width: '30px' }}
              onClick={(): void => {
                setRowData(params.data)
              }}
            >
              <MoreVertIcon fontSize="inherit" />
            </IconButton>
          </Box>
        ) : (
          params.value
        )
      },
    },
    {
      field: 'roles',
      width: 300,
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return params.value.map((role: string) => {
          return (
            <Chip
              key={role}
              variant="outlined"
              color={
                role === 'UserAdmin:RTSEmployee'
                  ? 'info'
                  : role === 'PortalSuperUser:RTSEmployee'
                  ? 'primary'
                  : role === 'User:Customer:AllFeatures'
                  ? 'secondary'
                  : role === 'User:Customer:SupportOnly'
                  ? 'success'
                  : role === 'User:Customer:Procurement'
                  ? 'warning'
                  : 'default'
              }
              size="small"
              label={role}
              sx={{ mr: 0.5, textTransform: 'uppercase', fontSize: '0.75rem' }}
            />
          )
        })
      },
    },
    {
      headerName: 'Status',
      width: 100,
      field: 'userStatus',
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        if (params.value === 'Accepted') {
          return (
            <Typography component="span" color="primary" fontFamily="inherit">
              {params.value}
            </Typography>
          )
        } else {
          return (
            <Typography component="span" fontFamily="inherit">
              {params.value}
            </Typography>
          )
        }
      },
    },

    {
      headerName: 'Invitation Sent On',
      width: 190,
      field: 'invitationSendDateTime',
      sort: 'desc',
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
    },
    {
      headerName: 'Invitation Accepted on',
      width: 195,
      field: 'invitationAcceptedDateTime',
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
    },
    {
      headerName: 'Accepted Privacy & Terms',
      field: 'hasAcceptedCustomerPrivacyAndTerms',
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return params.value ? (
          <CheckIcon color="primary" fontSize="small" />
        ) : (
          <CloseIcon color="error" fontSize="small" />
        )
      },
    },
    {
      headerName: 'Last Signed-In On',
      width: 180,
      field: 'lastSignInDateTime',
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
    },
    {
      headerName: 'Pello Device Id',
      width: 200,
      field: 'pelloId',
    },
    {
      pinned: 'right',
      resizable: false,
      field: 'isActive',
      headerName: 'Active',
      filter: 'agNumberColumnFilter',
      cellStyle: {
        justifyContent: 'center',
      },
      width: 95,
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return (
          <Switch
            checked={params.value}
            size="small"
            onChange={(event): void => {
              handleUserActive(event, params.data)
            }}
            disabled={params.data.adObjectId === null}
          />
        )
      },
    },
    {
      pinned: 'right',
      resizable: false,
      field: '',
      width: 70,
      filter: false,
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return (
          <Tooltip title="Delete User" arrow placement="left-start">
            <span>
              <IconButton
                color="error"
                onClick={(): void => handleUserDelete(params.data)}
                disableRipple
                size="small"
                disabled={params.data.adObjectId === null}
              >
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        )
      },
    },
    {
      pinned: 'right',
      resizable: false,
      field: '',
      width: 70,
      filter: false,
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return (
          <Tooltip title="Resend Invitation" arrow placement="left-start">
            <span>
              <IconButton
                color="secondary"
                onClick={(): void => handleUserReInvite(params.data)}
                disableRipple
                size="small"
                disabled={params.data.userStatus === 'Accepted'}
              >
                <ForwardToInbox fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        )
      },
    },
  ]

  useEffect(() => {
    const updatedUserList = userList.map(user => ({
      ...user,
      pelloId: user.billingAccounts
        .map(bacc => bacc.pelloId)
        .filter(pelloId => pelloId),
    }))
    setRowsData(updatedUserList)
  }, [userList])

  const handleClose = (): void => {
    setRowData(null)
  }
  const onBtnExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv({
      fileName: 'usermanagement.csv',
    })
  }, [])
  return (
    <Card
      sx={{
        width: '100%',
        mt: 2,
        background: '#fff',
      }}
    >
      <ConfirmationDialog
        open={confirmDialogOpen}
        icon="highlight_off"
        description="Are you sure you want to delete this user ?"
        action={
          <>
            <Button
              autoFocus
              color="primary"
              variant="contained"
              onClick={handleDeleteClose}
              disableElevation
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              color="warning"
              autoFocus
              variant="contained"
              onClick={handleDeleteConfirm}
              disableElevation
            >
              Delete
            </Button>
          </>
        }
      />
      <CardHeader title="All Users" />
      <CardContent>
        <Dialog
          fullScreen={fullScreen}
          open={rowData !== null}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Allowed billing accounts with sites
          </DialogTitle>
          <DialogContent>
            {rowData ? (
              <TreeView
                aria-label="Billing accounts navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{
                  maxHeight: 240,
                  flexGrow: 1,
                  overflowY: 'auto',
                }}
              >
                {rowData.billingAccounts?.map(billingAccount => {
                  return (
                    <TreeItem
                      nodeId={billingAccount.id}
                      label={billingAccount.name}
                      key={billingAccount.id}
                    >
                      {billingAccount.sites?.length ? (
                        billingAccount.sites.map(site => (
                          <TreeItem
                            nodeId={site.id}
                            label={site.name}
                            key={site.id}
                            sx={{ mt: 0.5 }}
                          />
                        ))
                      ) : (
                        <TreeItem
                          nodeId={`allSites_${billingAccount.id}`}
                          label="All sites selected"
                          sx={{ mt: 0.5 }}
                        />
                      )}
                    </TreeItem>
                  )
                })}
              </TreeView>
            ) : (
              'No billing accounts available for the selected user.'
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Box sx={{ display: 'flex', justifyContent: 'end', mb: 1 }}>
          <Button
            variant="outlined"
            onClick={onBtnExport}
            size="small"
            sx={{ mr: 1 }}
          >
            Download to CSV
          </Button>
          {hasGridFilter ? (
            <Button
              variant="outlined"
              onClick={clearFilters}
              endIcon={<FilterAltOffIcon />}
              size="small"
            >
              Clear filters
            </Button>
          ) : null}
        </Box>
        {!loading ? (
          <div
            className="ag-theme-alpine"
            style={{ height: 470, width: '100%' }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rowsData}
              columnDefs={columnDefs}
              animateRows={true}
              defaultColDef={defaultColDef}
              enableCellChangeFlash={true}
              paginationPageSizeSelector={paginationPageSizeSelector}
              onFilterChanged={filterChanged}
              onCellClicked={handleCellClick}
              pagination={true}
              paginationPageSize={10}
              suppressRowClickSelection={true}
              suppressRowDeselection={true}
              suppressCellFocus={true}
              getRowStyle={getRowStyle}
            />
          </div>
        ) : (
          <Loading height="400px" message="Loading Users..." />
        )}
      </CardContent>
    </Card>
  )
}

export default UserList
